import React, {Fragment} from "react";
import {Campaign} from "../CampaignListPage";
import {Button, Card, Form} from "react-bootstrap";
import ApiRest from "../../../../service/ApiRest";

interface Props {
    element: Campaign,
    getData: () => void,
}

export default function CampaignTokenPage(props: Props) {
    const {element} = props;

    const generateToken = () => {
        ApiRest.getElementRel('campaign', element.id, 'generateToken').then(() => {
            props.getData();
        })
    }

    return (
        <Card>
            <Card.Body>


                <Form.Group>
                    <Form.Label>Please send generated token to client</Form.Label>
                    <Form.Control value={element.affToken} readOnly={true}/>
                </Form.Group>


                <Form.Group>
                    <Form.Label>Client access url</Form.Label>
                    <Form.Control value={"https://" + window.location.host + "/client/" + element.affToken}
                                  readOnly={true}/>
                </Form.Group>

            </Card.Body>
            <Card.Footer>
                {!element.affToken &&
                <Button onClick={generateToken} variant={"secondary"}>Generate client token</Button>}
                {!!element.affToken && <Fragment>
                    <Button onClick={generateToken} variant={"outline-secondary"}>Regenerate client token</Button>
                </Fragment>}

            </Card.Footer>
        </Card>
    )
}
