class ApiClientService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/public';
        if (process.env.NODE_ENV === "development") {
            this.baseUrl = 'http://localhost:8000/app/public';
        }
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    };

    getCampaign = (token: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({token})
        };

        return fetch(this.baseUrl + '/campaign', requestOptions).then(res => res.json());
    }

    savePayoutOptions = (token: string,
                         payoutOption: number,
                         payoutEmail: string,
                         payoutOwnerName: string,
                         payoutAccountNumber: string,
                         payoutAchNumber: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify(
                {
                    token,
                    payoutOption,
                    payoutEmail,
                    payoutOwnerName,
                    payoutAccountNumber,
                    payoutAchNumber
                }
            )
        };

        return fetch(this.baseUrl + '/savePayoutOptions', requestOptions).then(res => res.json());
    }
}

const ApiClient = new ApiClientService();
export default ApiClient;
