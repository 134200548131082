import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../service/ApiRest";
import {Card, Col, Container, Row, Table} from "react-bootstrap";

export interface Customer {
    id: number,
    uniqueId: string,
    createTime: string,
    campaign: string,
    email: string,
    orders: number,
    total: number,
    comm: number,
    available: number,
}

export interface Order {
    id: number,
    total: number,
    externalId: string,
    orderTime: string,
    status: string,
    email: string,
}

const moduleName = 'customer';

export default function CustomerListPage() {
    const history = useHistory();

    const [customers, setCustomers] = useState<Customer[]>([])

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setCustomers(res);
        })
    }

    useEffect(getData, []);

    const goTo = (item: Customer) => {
        // history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"pb-5"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Customers
                                </Col>
                                <Col className={"text-right"}>
                                    {/*<Button variant={"success"} onClick={goToNew}>New</Button>*/}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped hover className={"mt-2"}>
                                <thead>
                                <tr>
                                    <th scope={"col"} className={"text-center"} style={{width: 90}}>ID</th>
                                    <th scope={"col"}>Email</th>
                                    <th scope={"col"}>Campaign</th>
                                    <th scope={"col"} className={"text-right"}>Orders</th>
                                    <th scope={"col"} className={"text-right"}>Total</th>
                                    <th scope={"col"} className={"text-right"}>Commission</th>
                                    <th scope={"col"} className={"text-right"}>Available</th>
                                </tr>
                                </thead>
                                <tbody>
                                {customers.map((item: Customer) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <th className={"text-center"} scope={"row"}>{item.id}</th>
                                            <td>{item.email}</td>
                                            <td>{item.campaign}</td>
                                            <td className={"text-right"}>{item.orders}</td>
                                            <td className={"text-right"}>{item.total.toFixed(2)}</td>
                                            <td className={"text-right"}>{item.comm.toFixed(2)}</td>
                                            <td className={"text-right"}>{item.available.toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
