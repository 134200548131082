import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {Card} from "react-bootstrap";
import ApiRest from "../../../service/ApiRest";
import CampaignForm from "../../forms/CampaignForm";
import CampaignScript from "./Components/CampaignScript";
import CampaignCustomers from "./Components/CampaignCustomers";
import CampaignTokenPage from "./Components/CampaignTokenPage";
import DeleteButton from "./Components/DeleteButton";
import {toast} from "react-toastify";
import ApiProfile from "../../../service/ApiProfile";
import CampaignSignupUrl from "./Components/CampaignSignupUrl";
import CampaignOrders from "./Components/CampaignOrders";

const moduleName = 'campaign';

interface ParamTypes {
    id: string
}

const defElement = {
    id: 0,
    name: '',
    uniqueId: '',
    stripePkKey: '',
    stripeSkKey: '',
    affToken: '',
    customers: 0,
    leads: 0,
    mainPurchaseCommission: 0,
    renewPurchaseCommission: 0,
    type: 0,

    payoutOption : 0,
    payoutEmail : '',
    payoutOwnerName: '',
    payoutAccountNumber: '',
    payoutAchNumber : '',
}


export default function CampaignPage() {
    const history = useHistory();

    const [signupToken, setSignupToken] = useState('');

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (name: string,
                      stripePkKey: string,
                      stripeSkKey: string,
                      mainPurchaseCommission: number,
                      renewPurchaseCommission: number,
                      type: number,
                      payoutOption : number,
                      payoutEmail : string,
                      payoutOwnerName: string,
                      payoutAccountNumber: string,
                      payoutAchNumber : string,
    ) => {
        if (id === 'signup') {
            ApiProfile.getSignupLink(
                stripePkKey,
                stripeSkKey,
                mainPurchaseCommission,
                renewPurchaseCommission,
                type,
            ).then(res => {
                setSignupToken(res.code);
            })
        } else if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    name,
                    stripePkKey,
                    stripeSkKey,
                    mainPurchaseCommission,
                    renewPurchaseCommission,
                    type,

                    payoutOption,
                    payoutEmail,
                    payoutOwnerName,
                    payoutAccountNumber,
                    payoutAchNumber,
                }
            ).then(res => {
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);

                toast.success("Item saved");
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    name,
                    stripePkKey,
                    stripeSkKey,
                    mainPurchaseCommission,
                    renewPurchaseCommission,
                    type,
                    payoutOption,
                    payoutEmail,
                    payoutOwnerName,
                    payoutAccountNumber,
                    payoutAchNumber,
                }).then(res => {
                toast.success("Item saved");
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new' || id === 'signup') ?
        <Fragment>
            <CampaignForm element={element} saveData={saveData} isSignup={id === 'signup'}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Fragment>
            {!!element && element.id > 0 &&
            <Card>
                <Card.Body>
                    <DeleteButton id={element.id}/>
                </Card.Body>
            </Card>
            }
            {!!element && element.id > 0 &&
            <CampaignScript element={element}/>
            }
            {!!element && element.id > 0 && <CampaignTokenPage getData={getData} element={element}/>}


            {signupToken && <CampaignSignupUrl token={signupToken} />}

        </Fragment>
    );


    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

            {!!element && element.id > 0 && <CampaignCustomers element={element}/>}
            {!!element && element.id > 0 && <CampaignOrders element={element}/>}

        </Fragment>
    )
}
