import React, {Fragment, useState} from "react";
import {Campaign} from "./CampaignListPage";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import ApiClient from "../../../service/ApiClient";
import {toast} from "react-toastify";

interface Props {
    element: Campaign,
}

export default function ClientCampaignPayoutForm(props: Props) {
    const {element} = props;

    const [payoutOption, setPayoutOption] = useState(element.payoutOption.toString());
    const [payoutEmail, setPayoutEmail] = useState(element.payoutEmail);
    const [payoutOwnerName, setPayoutOwnerName] = useState(element.payoutOwnerName);

    const [payoutAccountNumber, setPayoutAccountNumber] = useState(element.payoutAccountNumber);
    const [payoutAchNumber, setPayoutAchNumber] = useState(element.payoutAchNumber);

    const saveData = () => {
        ApiClient.savePayoutOptions(
            element.affToken,
            parseInt(payoutOption, 10),
            payoutEmail,
            payoutOwnerName,
            payoutAccountNumber,
            payoutAchNumber
        ).then(() => {
            toast.success("Success!");
        })
    }

    return (
        <Fragment>
            <Card>
                <Card.Header>Payout</Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Please choose Payout Method</Form.Label>
                            <Form.Control value={payoutOption}
                                          as={"select"}
                                          onChange={e => setPayoutOption(e.target.value)}>
                                <option value={0}>PayPal</option>
                                <option value={1}>Payoneer</option>
                                <option value={2}>Wire Transfer</option>
                            </Form.Control>
                        </Form.Group>
                        {payoutOption !== '2' &&
                        <Form.Group>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control value={payoutEmail}
                                          onChange={e => setPayoutEmail(e.target.value)}/>
                        </Form.Group>
                        }
                        {payoutOption === '2' && <Fragment>
                            <Form.Group>
                                <Form.Label>Beneficial owner name</Form.Label>
                                <Form.Control value={payoutOwnerName}
                                              onChange={e => setPayoutOwnerName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Account number</Form.Label>
                                <Form.Control value={payoutAccountNumber}
                                              onChange={e => setPayoutAccountNumber(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ACH (Routing) number</Form.Label>
                                <Form.Control value={payoutAchNumber}
                                              onChange={e => setPayoutAchNumber(e.target.value)}/>
                            </Form.Group>
                        </Fragment>}
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>

                        </Col>
                        <Col className={"text-right"}>
                            <Button type={"button"} variant={"primary"}
                                    onClick={() => saveData()}>Save</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Fragment>
    )
}
