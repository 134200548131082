import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Button, Card, Col, Container, Row, Table} from "react-bootstrap";

export interface Campaign {
    id: number,
    name: string,
    uniqueId: string,
    stripePkKey: string,
    stripeSkKey: string,
    affToken: string,
    customers: number,
    leads: number,
    mainPurchaseCommission: number,
    renewPurchaseCommission: number,
    type: number,
    payoutOption : number,
    payoutEmail : string,
    payoutOwnerName: string,
    payoutAccountNumber: string,
    payoutAchNumber : string,
}

const moduleName = 'campaign';

export default function CampaignListPage() {
    const history = useHistory();

    const [campaigns, setCampaigns] = useState<Campaign[]>([])

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setCampaigns(res);
        })
    }

    useEffect(getData, []);

    const goTo = (item: Campaign) => {
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    const goToSignup = () => {
        history.push('/' + moduleName + '/signup');
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={"pb-5"}>
                        <Card.Header>
                            <Row>
                                <Col className={"v-center"}>
                                    Campaigns
                                </Col>
                                <Col sm={"auto"} className={"text-right"}>
                                    <Button variant={"outline-success"} onClick={goToSignup}>Signup form</Button>
                                </Col>
                                <Col sm={"auto"} className={"text-right"}>
                                    <Button variant={"success"} onClick={goToNew}>New</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped hover className={"mt-2"}>
                                <thead>
                                <tr>
                                    <th scope={"col"} className={"text-center"} style={{width: 90}}>ID</th>
                                    <th scope={"col"}>Title</th>
                                    <th scope={"col"}>Type</th>
                                    <th scope={"col"}>Token</th>
                                    <th scope={"col"} className={"text-right"}>Clicks</th>
                                    <th scope={"col"} className={"text-right"}>Customers</th>
                                </tr>
                                </thead>
                                <tbody>
                                {campaigns.map((item: Campaign) => {
                                    return (
                                        <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                            <th className={"text-center"} scope={"row"}>{item.id}</th>
                                            <td>{item.name}</td>
                                            <td>{item.type === 0 ? '%' : 'fix'}</td>
                                            <td>{item.uniqueId}</td>
                                            <td className={"text-right"}>{item.leads}</td>
                                            <td className={"text-right"}>{item.customers}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </Container>
    );
}
