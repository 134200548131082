import React from "react";
import {Card, Form} from "react-bootstrap";
import {Campaign} from "../CampaignListPage";

interface Props {
    element: Campaign
}

export default function CampaignScript(props: Props) {
    const {element} = props;

    const script = `<script type="text/javascript" src="https://${window.location.host}/scripts/AffTracking.js"></script>`

    const script2 = `<script type="text/javascript">
AffTrackingClass.startTracking('${element.uniqueId}');
</script>`

    const script3 = `https://${window.location.host}/welcome/${element.uniqueId}`;

    return (
        <Card>
            <Card.Body>
                <Form.Group>
                    <Form.Label>Please include this script into page</Form.Label>
                    <Form.Control value={script} readOnly={true}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Please include this script to start tracking</Form.Label>
                    <Form.Control value={script2} readOnly={true}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Aff url</Form.Label>
                    <Form.Control value={script3} readOnly={true}/>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
