import React from "react";
import {Card, Form} from "react-bootstrap";
import {Campaign} from "../CampaignListPage";

interface Props {
    token: string
}

export default function CampaignSignupUrl(props: Props) {
    const {token} = props;

    const script3 = `https://${window.location.host}/signup/${token}`;

    return (
        <Card>
            <Card.Body>

                <Form.Group>
                    <Form.Label>Signup url</Form.Label>
                    <Form.Control value={script3} readOnly={true}/>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
