import React, {Fragment, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Campaign} from "../pages/Campaigns/CampaignListPage";

interface Props {
    element: Campaign,
    saveData: (name: string,
               stripePkKey: string,
               stripeSkKey: string,
               mainPurchaseCommission: number,
               renewPurchaseCommission: number,
               type: number,
               payoutOption: number,
               payoutEmail: string,
               payoutAccountNumber: string,
               payoutAchNumber: string,
               payoutOwnerName: string,
    ) => void,
    isSignup?: (boolean)
}

export default function CampaignForm(props: Props) {
    const {element, saveData, isSignup} = props;
    const [type, setType] = useState(element.type);
    const [name, setName] = useState(element.name);
    const [stripePkKey, setStripePkKey] = useState(element.stripePkKey);
    const [stripeSkKey, setStripeSkKey] = useState(element.stripeSkKey);

    const [payoutOption, setPayoutOption] = useState(element.payoutOption.toString());
    const [payoutEmail, setPayoutEmail] = useState(element.payoutEmail);
    const [payoutOwnerName, setPayoutOwnerName] = useState(element.payoutOwnerName);

    const [payoutAccountNumber, setPayoutAccountNumber] = useState(element.payoutAccountNumber);
    const [payoutAchNumber, setPayoutAchNumber] = useState(element.payoutAchNumber);


    const [mainPurchaseCommission, setMainPurchaseCommission] = useState(element.mainPurchaseCommission.toString());
    const [renewPurchaseCommission, setRenewPurchaseCommission] = useState(element.renewPurchaseCommission.toString());

    const postFix = type === 0 ? '%' : 'USD';

    return (
        <Card>
            <Card.Header>Campaign</Card.Header>
            <Card.Body>
                <Form>
                    {!isSignup &&
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>Stripe SK key</Form.Label>
                        <Form.Control value={stripeSkKey} onChange={e => setStripeSkKey(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Stripe PK key</Form.Label>
                        <Form.Control value={stripePkKey} onChange={e => setStripePkKey(e.target.value)}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control value={type} as={"select"}
                                      onChange={e => setType(parseInt(e.target.value, 10))}>
                            <option value={0}>%</option>
                            <option value={1}>Fix</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>First purchase {postFix}</Form.Label>
                        <Form.Control value={mainPurchaseCommission}
                                      onChange={e => setMainPurchaseCommission(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Renew purchase {postFix}</Form.Label>
                        <Form.Control value={renewPurchaseCommission}
                                      onChange={e => setRenewPurchaseCommission(e.target.value)}/>
                    </Form.Group>

                    {!isSignup && <Fragment>
                        <Row>
                            <Col>
                                <h5>Payout</h5>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>Please choose Payout Method</Form.Label>
                            <Form.Control value={payoutOption}
                                          as={"select"}
                                          onChange={e => setPayoutOption(e.target.value)}>
                                <option value={0}>PayPal</option>
                                <option value={1}>Payoneer</option>
                                <option value={2}>Wire Transfer</option>
                            </Form.Control>
                        </Form.Group>
                        {payoutOption !== '2' &&
                        <Form.Group>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control value={payoutEmail}
                                          onChange={e => setPayoutEmail(e.target.value)}/>
                        </Form.Group>
                        }
                        {payoutOption === '2' && <Fragment>
                            <Form.Group>
                                <Form.Label>Beneficial owner name</Form.Label>
                                <Form.Control value={payoutOwnerName}
                                              onChange={e => setPayoutOwnerName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Account number</Form.Label>
                                <Form.Control value={payoutAccountNumber}
                                              onChange={e => setPayoutAccountNumber(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ACH (Routing) number</Form.Label>
                                <Form.Control value={payoutAchNumber}
                                              onChange={e => setPayoutAchNumber(e.target.value)}/>
                            </Form.Group>
                        </Fragment>}
                    </Fragment>}
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    name,
                                    stripePkKey,
                                    stripeSkKey,
                                    parseInt(mainPurchaseCommission, 10),
                                    parseInt(renewPurchaseCommission, 10),
                                    type,
                                    parseInt(payoutOption, 10),
                                    payoutEmail,
                                    payoutOwnerName,
                                    payoutAccountNumber,
                                    payoutAchNumber,
                                )}>Save</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
