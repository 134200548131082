import React from "react";
import {Button} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../../service/ApiRest";

interface Props {
    id: number
}

export default function DeleteButton(props: Props) {
    const history = useHistory();
    const {id} = props;

    const doDelete = () => {
        if (!window.confirm('Are you sure?')) return false;

        ApiRest.deleteElement('campaign', id).then(res => {
            history.push('/campaigns');
        });
    }

    return (
        <Button variant={"danger"} onClick={doDelete}>Delete</Button>
    )
}