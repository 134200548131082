import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import ApiClient from "../../../service/ApiClient";
import {Card, Col, Container, Form, Nav, Row} from 'react-bootstrap';
import CampaignCustomers from "./Components/CampaignCustomers";
import CampaignOrders from "./Components/CampaignOrders";
import ClientCampaignPayoutForm from "./ClientCampaignPayoutForm";

const moduleName = 'campaign';

interface ParamTypes {
    token: string
}

const defElement = {
    id: 0,
    name: '',
    uniqueId: '',
    stripePkKey: '',
    stripeSkKey: '',
    affToken: '',
    customers: 0,
    leads: 0,
    mainPurchaseCommission: 0,
    renewPurchaseCommission: 0,
    orders: 0,
    totalOrdersComm: 0,
    totalOrdersAvailable: 0,
    type: 0,

    payoutOption: 0,
    payoutEmail: '',
    payoutOwnerName: '',
    payoutAccountNumber: '',
    payoutAchNumber: '',
}


export default function ClientCampaignPage() {
    const history = useHistory();

    const [state, setState] = useState(0);

    const [element, setElement] = useState(defElement);
    const {token} = useParams<ParamTypes>();

    const getData = () => {
        if (!!token) {
            ApiClient.getCampaign(token).then(res => {
                if (res.id > 0) {
                    setElement(res);
                }
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [token]);

    const switchState = (e: any, newState: number) => {
        e.preventDefault();
        setState(newState);
    }

    const script3 = `https://${window.location.host}/welcome/${element.uniqueId}`;

    const postFix = element.type === 0 ? '%' : 'USD';

    return (
        <Fragment>
            <div className={"navbar container-fluid"}>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <Container>
                            <Row>
                                <div className="v-center col-sm-8">
                                    <a href="/" className="navbar-brand">{element.name}</a>
                                </div>
                                <div className={"col-sm-4"}>
                                    <Nav style={{justifyContent: 'flex-end'}}>
                                        <Nav.Link onClick={(e: any) => switchState(e, 0)}
                                                  href={'/client/' + element.affToken + '/summary'}>Customers</Nav.Link>
                                        <Nav.Link onClick={(e: any) => switchState(e, 1)}
                                                  href={'/client/' + element.affToken + '/orders'}>Orders</Nav.Link>
                                        <Nav.Link onClick={(e: any) => switchState(e, 2)}
                                                  href={'/client/' + element.affToken + '/payout'}>Payout</Nav.Link>
                                    </Nav>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
            <div className={"pt-4 pb-4 container"}>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Text>{element.name}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Clicks</Form.Label>
                                                <Form.Text>{element.leads}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Customers</Form.Label>
                                                <Form.Text>{element.customers}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Orders</Form.Label>
                                                <Form.Text>{element.orders}</Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Main purchase</Form.Label>
                                                <Form.Text>{element.mainPurchaseCommission} {postFix}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Renew</Form.Label>
                                                <Form.Text>{element.renewPurchaseCommission} {postFix}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Total, USD</Form.Label>
                                                <Form.Text>{element.totalOrdersComm.toFixed(2)}</Form.Text>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Available, USD</Form.Label>
                                                <Form.Text>{element.totalOrdersAvailable.toFixed(2)}</Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Affiliate url</Form.Label>
                                                <Form.Text>{script3}</Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>

                                </Card.Body>
                            </Card>
                            {!!element && element.id > 0 &&
                            (
                                <Fragment>
                                    {state === 0 && <CampaignCustomers element={element}/>}
                                    {state === 1 && <CampaignOrders element={element}/>}
                                    {state === 2 && <ClientCampaignPayoutForm element={element}/>}
                                </Fragment>
                            )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}
