class ApiProfileService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/profile';
        if (process.env.NODE_ENV === "development") {
            this.baseUrl = 'http://localhost:8000/app/profile';
        }
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    authUploadRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Authorization': token
            },
        }
    };
    getProfile = () => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(this.baseUrl + '/get', requestOptions).then(res => res.json());
    }

    getSignupLink = (stripePkKey: string,
                     stripeSkKey: string,
                     mainPurchaseCommission: number,
                     renewPurchaseCommission: number,
                     type: number
    ) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({
                stripePkKey,
                stripeSkKey,
                mainPurchaseCommission,
                renewPurchaseCommission,
                type
            })
        };

        return fetch(this.baseUrl + '/getSignupLink', requestOptions).then(res => res.json());
    }

    saveProfile = (email: string, password: string, passwordRepeat: string, fromName: string, affName: string, welcomeEmail: string,
                   logoUrl: string,
                   redirectUrl: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({
                email,
                password,
                passwordRepeat,
                fromName,
                affName,
                welcomeEmail,
                redirectUrl,
                logoUrl
            })
        };

        return fetch(this.baseUrl + '/save', requestOptions).then(res => res.json());
    }
}

const ApiProfile = new ApiProfileService();
export default ApiProfile;
