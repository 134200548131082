import React, {Fragment} from 'react';
import {NaeAuthLoginPage, NaeAuthRegisterPage} from 'nae-react-auth'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {UserProvider} from "./Components/User/UserProvider";
import CampaignListPage from "./Components/pages/Campaigns/CampaignListPage";
import CampaignPage from "./Components/pages/Campaigns/CampaignPage";
import CustomerListPage from "./Components/pages/CustomerListPage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientCampaignPage from "./Components/pages/Campaigns/ClientCampaignPage";
import AffPage from "./Components/pages/AffPage";
import SignupPage from "./Components/pages/SignupPage";
import UserProfilePage from "./Components/User/UserProfilePage";

function App() {
    return (
        <Router>
            <Switch>
                <Route path={"/login"}><NaeAuthLoginPage/></Route>
                <Route path={"/register"}><NaeAuthRegisterPage/></Route>

                <Route path={"/client/:token"}><ClientCampaignPage/></Route>

                <Route path={"/welcome/:id"}><AffPage/></Route>

                <Route path={"/signup/:token"}><SignupPage/></Route>

                {/*<Route path={"/password-remind"}><PasswordRemindPage/></Route>*/}

                <UserProvider>
                    <Fragment>
                        <Route path={"/campaigns"}><CampaignListPage/></Route>
                        <Route path={"/campaign/:id"}><CampaignPage/></Route>

                        <Route path={"/customers"}><CustomerListPage/></Route>

                        <Route path={"/settings"}><UserProfilePage/></Route>
                    </Fragment>
                </UserProvider>
            </Switch>
            <ToastContainer/>
        </Router>
    );
}

export default App;
