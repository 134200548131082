import React, {Fragment, useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
// import useLocalStorage from "../../Hooks/useLocalStorage";
// import ApiProfile from "../../service/ApiProfile";
import {NaeLayout, NaeNavBar} from 'nae-react-core-styles'
import {NaeAuthLogoutBtn} from 'nae-react-auth'
import {Nav} from "react-bootstrap";
import useLocalStorage from "../../Hooks/useLocalStorage";
import ApiProfile from "../../service/ApiProfile";

export interface User {
    email: string,
    fromName: string,
    affName: string,
    welcomeEmail: string,
    redirectUrl: string,
    logoUrl: string,
}

interface ProviderValue {
    user: User
}

const defUser : User = {
    email: '',
    fromName: '',
    affName: '',
    welcomeEmail: '',
    redirectUrl: '',
    logoUrl: '',
}

export const UserContext = React.createContext<ProviderValue>({
    user: defUser
})
export const useUser = () => useContext(UserContext);

interface Props {
    children: any,
}

export const UserProvider = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(defUser);
    const [token] = useLocalStorage('token', '');
    const history = useHistory();

    const navigate = (e: any, route: string) => {
        e.preventDefault();
        history.push('/' + route);
    }

    const getData = () => {
        ApiProfile.getProfile().then(res => {
            if (!!res.isError) {
                history.push('/login');
                return;
            }
            setUser(res);
            setLoaded(true);
        }).catch(e => {
            history.push('/login');
        })
    }
    useEffect(getData, [token, history]);

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <UserContext.Provider value={{
            user
        }}>
            {!!user.email ? <NaeLayout.NaePageWrapper top={<NaeNavBar.NaeTopNavbar middleComponent={
                <Nav>
                    <Nav.Link href={'/campaigns'} onClick={(e: any) => navigate(e, 'campaigns')}>Campaigns</Nav.Link>

                    <Nav.Link href={'/customers'} onClick={(e: any) => navigate(e, 'customers')}>Customers</Nav.Link>

                    <Nav.Link href={'/settings'} onClick={(e: any) => navigate(e, 'settings')}>Settings</Nav.Link>

                </Nav>
            } rightComponent={<NaeAuthLogoutBtn/>}/>}>
                {props.children}
            </NaeLayout.NaePageWrapper> : <Redirect to={"/login"}/>}
        </UserContext.Provider>

    )
}
