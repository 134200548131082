import React, {useState} from "react";
import {Button, Card, Container, Form} from "react-bootstrap";
import {useUser} from "./UserProvider";

import {NaeLayout} from 'nae-react-core-styles'
import ApiProfile from "../../service/ApiProfile";

export default function UserProfilePage() {
    const {user} = useUser();
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [fromName, setFromName] = useState(user.fromName);
    const [affName, setAffName] = useState(user.affName);
    const [welcomeEmail, setWelcomeEmail] = useState(user.welcomeEmail);

    const [logoUrl, setLogoUrl] = useState(user.logoUrl);
    const [redirectUrl, setRedirectUrl] = useState(user.redirectUrl);

    const doSave = () => {
        ApiProfile.saveProfile(email, password, passwordRepeat, fromName, affName, welcomeEmail, logoUrl, redirectUrl).then(res => {
            if (!!res.isError) {
                alert(res.error.description);
                return;
            }
        })
    }

    return (
        <Container>

            <NaeLayout.NaeCenterColumn margins={3}>
                <Card>
                    <Card.Header>Profile</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    E-mail
                                </Form.Label>

                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)}
                                              autoComplete="nope"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    Password
                                </Form.Label>

                                <Form.Control type={"password"} value={password}
                                              onChange={(e) => setPassword(e.target.value)} autoComplete="nope"/>

                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Repeat password
                                </Form.Label>

                                <Form.Control type={"password"} value={passwordRepeat}
                                              onChange={(e) => setPasswordRepeat(e.target.value)} autoComplete="nope"/>
                            </Form.Group>
                            <hr/>
                            <Form.Group>
                                <Form.Label>
                                    From name
                                </Form.Label>

                                <Form.Control value={fromName} onChange={(e) => setFromName(e.target.value)}
                                              autoComplete="nope"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Aff name
                                </Form.Label>

                                <Form.Control value={affName} onChange={(e) => setAffName(e.target.value)}
                                              autoComplete="nope"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Welcome Email
                                </Form.Label>

                                <Form.Control value={welcomeEmail} onChange={(e) => setWelcomeEmail(e.target.value)}
                                              autoComplete="nope" as={'textarea'} rows={20}/>
                            </Form.Group>
                            <div>
                                {"{email} - user email"}<br/>
                                {"{statsUrl} - dashboard"}<br/>
                                {"{personalUrl} - sharing"}
                            </div>
                            <hr/>
                            <Form.Group>
                                <Form.Label>
                                    Logo url
                                </Form.Label>

                                <Form.Control value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)}
                                              autoComplete="nope"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Redirect url
                                </Form.Label>

                                <Form.Control value={redirectUrl} onChange={(e) => setRedirectUrl(e.target.value)}
                                              autoComplete="nope"/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <Card.Footer className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => doSave()}>Save</Button>
                    </Card.Footer>
                </Card>
            </NaeLayout.NaeCenterColumn>

        </Container>
    )
}
