import React, {Fragment, useEffect, useState} from "react";
import {useParams} from 'react-router-dom';

export default function AffPage() {
    const [logo, setLogo] = useState('');
    const [redirect, setRedirect] = useState('');

    const {id}: any = useParams();
    const addJsToDocument = (js: string) => {
        const script = document.createElement('script');
        script.src = js;
        document.head.appendChild(script);
    }

    const doWelcome = () => {

        let baseUrl = '/app/public';
        if (process.env.NODE_ENV === "development") {
            baseUrl = 'http://localhost:8000/app/public';
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        fetch(baseUrl + '/welcome-redirect/' + id, requestOptions).then(res => res.json()).then(res => {
            setRedirect(res.r);
            setLogo(res.l);
        });
    }

    useEffect(doWelcome, []);

    useEffect(() => {
        if (redirect) {
            addJsToDocument('https://' + window.location.host + '/scripts/AffTracking.js');

            setTimeout(() => {
                // @ts-ignore
                window.AffTrackingClass.startTracking(id);
                setTimeout(() => {
                    window.location.href = redirect;
                }, 500);
            }, 500);
        }
    }, [redirect]);

    return <Fragment>
        <div style={{background: '#000', width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {logo && <img src={logo} style={{position: 'absolute', top: 50, maxWidth: 200, maxHeight: 200}}/>}
            <div style={{color: "#fff", textAlign: 'center'}}>Loading...</div>
        </div>

    </Fragment>
}
