import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import {Card, Col, Row, Table} from "react-bootstrap";
import {Customer} from "../../CustomerListPage";
import {Campaign} from "../CampaignListPage";
import ApiRest from "../../../../service/ApiRest";

const moduleName = 'campaign';

interface Props {
    element: Campaign
}

export default function CampaignCustomers(props: Props) {
    const history = useHistory();

    const [customers, setCustomers] = useState<Customer[]>([])

    const getData = () => {
        if (props.element.id > 0) {
            ApiRest.getElementRel(moduleName, props.element.id, 'customers').then(res => {
                setCustomers(res);
            })
        }
    }

    useEffect(getData, [props.element.id]);

    const goTo = (item: Customer) => {
        // history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (

        <Card className={"pb-5"}>
            <Card.Header>
                <Row>
                    <Col className={"v-center"}>
                        Customers
                    </Col>
                    <Col className={"text-right"}>
                        {/*<Button variant={"success"} onClick={goToNew}>New</Button>*/}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table striped hover className={"mt-2"}>
                    <thead>
                    <tr>
                        <th scope={"col"} className={"text-center"} style={{width: 90}}>ID</th>
                        <th scope={"col"}>Email</th>
                        <th scope={"col"} className={"text-right"}>Orders</th>
                        <th scope={"col"} className={"text-right"}>Total</th>
                        <th scope={"col"} className={"text-right"}>Commission</th>
                        <th scope={"col"} className={"text-right"}>Available</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.map((item: Customer) => {
                        return (
                            <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                <th className={"text-center"} scope={"row"}>{item.id}</th>
                                <td>{item.email}</td>
                                <td className={"text-right"}>{item.orders}</td>
                                <td className={"text-right"}>{item.total.toFixed(2)}</td>
                                <td className={"text-right"}>{item.comm.toFixed(2)}</td>
                                <td className={"text-right"}>{item.available.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>

    );
}
