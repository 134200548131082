import React, {Fragment, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import {toast} from "react-toastify";

interface ParamTypes {
    token: string
}


export default function SignupPage() {
    const history = useHistory();

    const [email, setEmail] = useState('');

    const doRegister = () => {
        if (!email) {
            toast.error('Fill email field');
            return;
        }
        let baseUrl = '/app/public';
        if (process.env.NODE_ENV === "development") {
            baseUrl = 'http://localhost:8000/app/public';
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                email,
                url: window.location.host
            })
        };

        return fetch(baseUrl + '/clientSignup', requestOptions).then(res => res.json()).then(res => {
            if (!!res.token) {
                history.push('/client/' + res.token);
            }
        });
    }


    const {token} = useParams<ParamTypes>();


    return (
        <Fragment>
            <div className='full-height v-center'>
                <Container className='mt-n20vh'>
                    <Row>
                        <Col sm={3}/>
                        <Col>
                            <Card>
                                <Card.Header>Sign up form</Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>E-mail:</Form.Label>

                                            <Form.Control
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>

                                    </Form>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>

                                        <Col className='text-right'>
                                            <Button
                                                type='button'
                                                variant='primary'
                                                onClick={() => doRegister()}
                                            >
                                                Sign up
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col sm={3}/>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}
